import { useEffect, useRef } from "react";
import Image from "next/image";
import Container from "./Container";
import VideoPlay from "./VideoPlay";
import { moveImageBox } from "../helpers/animations";

export default function TextWithImage({
  image,
  imageAlt = "",
  onPlay,
  imageRatio = "",
  childrenWidth = "max-w-md",
  offsets = undefined,
  children,
}) {
  const refImage = useRef();
  const refBox = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (refImage.current && refBox.current) {
        if (offsets) {
          moveImageBox(refImage, refBox, offsets[0], offsets[1]);
        } else {
          moveImageBox(refImage, refBox);
        }
      }
    };

    window?.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Container>
      <section className="flex flex-col lg:flex-row items-center lg:items-stretch md:gap-20 lg:gap-16">
        <div className="relative w-full flex-1 order-2 md:order-1">
          <div
            className="hidden md:block w-[60%] h-[60%] absolute top-1/3 left-[45%] bg-white shadow-lg rounded-md ease-out transition-transform duration-500"
            ref={refBox}
          />

          <div
            className={`relative flex w-screen sm:w-auto mt-20 md:mt-0 -mx-6 md:mx-0 md:rounded-md overflow-hidden ease-out transition-transform duration-1000 will-change-transform group ${imageRatio}`}
            ref={refImage}
          >
            <Image
              src={image}
              className="will-change-transform group-hover:scale-110 group-hover:-rotate-2 transition-transform ease-out duration-1000"
              alt={imageAlt}
            />
            {onPlay && <VideoPlay showLabel={true} onPlay={onPlay} />}
          </div>
        </div>

        <div className="relative flex-1 order-1 md:order-2 pt-12">
          <div className={childrenWidth}>{children}</div>
        </div>
      </section>
    </Container>
  );
}
