import {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/css';
import SvgArrowLeft from "../svg/SvgArrowLeft";
import SvgArrowRight from "../svg/SvgArrowRight";
import Image from "next/image";

SwiperCore.use([Navigation]);

export default function Gallery({images = []}) {
    const [loaded, setLoaded] = useState(false);
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    useEffect(() => {
        if (navigationPrevRef.current) {
            setLoaded(true);
        }
    }, [navigationPrevRef, navigationNextRef]);

    return (
        <div className="relative flex flex-col">
            {loaded && (
                <Swiper
                    className="w-full"
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    loop={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                >
                    {images.map((item, key) => {
                        return (
                            <SwiperSlide key={`Gallery Item: ${key}`}>
                                <div className="relative h-[400px] md:h-gallery bg-center bg-cover md:rounded-lg">
                                    <Image src={item}
                                           alt={`Gallery Image ${key}`}
                                           priority
                                           className="md:rounded-lg object-cover w-full h-full"
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            )}
            <div
                className="absolute divided bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 z-20 flex items-stretch rounded-lg overflow-hidden shadow-xl">
                <button ref={navigationPrevRef}
                        className="py-10 px-14 bg-white text-black mouse-hover:bg-black mouse-hover:text-white transition-colors will-change-transform duration-300">
                    <SvgArrowLeft width={25} id="gallery"/>
                </button>
                <button ref={navigationNextRef}
                        className="py-10 px-14 bg-white text-black mouse-hover:bg-black mouse-hover:text-white transition-colors will-change-transform duration-300">
                    <SvgArrowRight width={25} id="gallery"/>
                </button>
            </div>
        </div>
    )
}
